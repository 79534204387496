import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Headroom from "react-headroom"
import logo from "../images/logo.png" // Tell webpack this JS file uses this image
import '../styles/header.css'
import scrollTo from 'gatsby-plugin-smoothscroll';
import logonew from "../images/logo-new.png" // Tell webpack this JS file uses this image





const Header = () => {
  
    return (
        <div>
          
          <div id="barra-nav">
          <div className="nav-container">
        {/* <div className="brand">
        <Link to="/" title="Homepage"><img src={logonew} alt="Logo" /></Link>
        </div> */}
        <nav>
          {/* <div className="nav-mobile"><a id="nav-toggle"><span></span></a></div> */}
          <ul className="nav-list">
         
            {/* <li>
            <a onClick={() => scrollTo('#section-1')}  title="Origem" style={{ textDecoration: 'none' }}> <span className="menu-label gold-color sneak-regular ">Origem</span></a>
            </li>
            <li>
            <a onClick={() => scrollTo('#section-2')}   title="Sustentabilidade" style={{ textDecoration: 'none' }}><span className="menu-label gold-color sneak-regular ">Sustentabilidade</span></a> 
    </li>
                <li>
                <a onClick={() => scrollTo('#section-3')}   title="Ponto da Carne" style={{ textDecoration: 'none' }}><span className="menu-label gold-color sneak-regular ">Ponto da Carne</span></a> 
    
                </li>
                <li>
                <a onClick={() => scrollTo('#section-4')}   title="Sugestões" style={{ textDecoration: 'none' }}><span className="menu-label gold-color sneak-regular">Sugestões</span></a> 
    
                </li> */}
              
              
              </ul>
            
           
       
           
         
        </nav>
      </div></div>
       
       
    
        </div>
        
      )
    
  

}


export default Header

